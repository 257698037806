<template>
  <div class="login">
    <div class="content">
      <div class="title">
        <img height="40" src="../assets/img/Index_JobSeeker/logo.png" alt="" />
      </div>
      <div class="from">
        <h3>登录</h3>
        <p class="tag">还没有账号？<a href="/register">去注册</a></p>
        <div class="tab-catalog-00">
          <a
            :class="loginType == 1 ? 'atthis' : ''"
            @click="tabType(1)"
            href="javascript:void(0)"
            >我要找工作</a
          >
          <a
            :class="loginType == 2 ? 'atthis' : ''"
            @click="tabType(2)"
            href="javascript:void(0)"
            >我要招人</a
          >
        </div>
        <transition-group name="el-zoom-in-center" tag="div">
          <div class="bd" v-show="loginType == 1 && !phone" key="job">
            <div class="item">
              <div class="icon-name"></div>
              <input
                autocomplete="off"
                type="text"
                class="name"
                placeholder="请输入账号"
                v-model="job.account"
              />
            </div>
            <div class="item">
              <div class="icon-password"></div>
              <input
                autocomplete="off"
                type="password"
                class="password"
                placeholder="请输入登录密码"
                v-model="job.password"
              />
            </div>
            <div class="item code">
              <input
                @keyup.enter="login"
                autocomplete="off"
                type="text"
                placeholder="请输入图形验证码"
                v-model="job.captcha"
              />
              <img
                @click="getcode"
                :src="
                  codeImg.image ? 'data:image/png;base64,' + codeImg.image : ''
                "
                alt=""
                srcset=""
              />
            </div>
          </div>
          <div class="bd" v-show="loginType == 1 && phone" key="jobphone">
            <div class="item">
              <div class="icon-code"></div>
              <input
                autocomplete="off"
                type="text"
                maxlength="11"
                class="phone"
                placeholder="请输入手机号"
                v-model="job.tel"
              />
            </div>
            <div class="item code">
              <div class="icon-code"></div>
              <input
                autocomplete="off"
                type="text"
                placeholder="请输入图形验证码"
                v-model="job.captcha"
              />
              <img
                @click="getcode"
                :src="
                  codeImg.image ? 'data:image/png;base64,' + codeImg.image : ''
                "
                alt=""
                srcset=""
              />
            </div>
            <div class="item">
              <input
                type="text"
                autocomplete="off"
                @keyup.enter="login"
                class="code"
                placeholder="请输入验证码"
                v-model="job.shortCaptcha"
              />
              <div class="yzm" v-show="shortCode" @click="shortCaptcha">
                获取验证码
              </div>
              <div class="yzm send" v-show="!shortCode" @click="shortCaptcha">
                {{ count }}s后重新获取
              </div>
            </div>
          </div>
          <div class="bd" v-show="loginType == 2" key="recruit">
            <div class="item">
              <div class="icon-name"></div>
              <input
                autocomplete="off"
                type="text"
                class="name"
                placeholder="请输入账号"
                v-model="recruit.account"
              />
            </div>
            <div class="item">
              <div class="icon-password"></div>
              <input
                autocomplete="off"
                type="password"
                class="password"
                placeholder="请输入登录密码"
                v-model="recruit.password"
              />
            </div>
            <div class="item code">
              <input
                type="text"
                @keyup.enter="login"
                placeholder="请输入图形验证码"
                v-model="recruit.captcha"
              />
              <img
                @click="getcode"
                :src="
                  codeImg.image ? 'data:image/png;base64,' + codeImg.image : ''
                "
                alt=""
                srcset=""
              />
            </div>
          </div>
        </transition-group>
        <div class="tips">
          <p @click="$router.push('/password?type=' + loginType)">找回密码</p>
          <span @click="phone = false" v-if="loginType == 1 && phone"
            >密码登录</span
          >
          <span @click="phone = true" v-if="loginType == 1 && !phone"
            >短信登录</span
          >
        </div>
        <div class="btn" @click="login">
          <img src="../assets/img/login/btn-login.png" alt="" srcset="" />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import event from "@/utils/event";
import { captcha, login, shortCaptcha } from "@/api/login";
export default {
  name: "Login",
  data() {
    return {
      codeImg: {},
      loginType: 1,
      phone: false,
      shortCode: true,
      count: 60,
      job: {
        account: "",
        password: "",
        captcha: "",
        tel: "",
        shortCaptcha: "",
      },
      recruit: {
        account: "",
        password: "",
        captcha: "",
        tel: "",
        shortCaptcha: "",
      },
    };
  },
  created() {
    this.getcode();
  },
  components: {},
  methods: {
    // 短信验证码
    shortCaptcha() {
      this.shortCode = false;
      let params = {};
      if (this.loginType == 1) {
        if (!this.job.tel) {
          this.$message.warning("手机不能为空！");
          this.shortCode = true;
          return;
        }
        if (this.codeImg.code !== this.job.captcha) {
          this.$message.warning("图形验证码输入错误请重新输入！");
          this.shortCode = true;
          this.getcode();
          return;
        }
        params = {
          tel: this.job.tel,
        };
      } else {
        if (!this.recruit.tel) {
          this.$message.warning("手机不能为空！");
          this.shortCode = true;
          return;
        }
        if (this.codeImg.code !== this.recruit.captcha) {
          this.$message.warning("图形验证码输入错误请重新输入！");
          this.shortCode = true;
          this.getcode();
          return;
        }
        params = {
          tel: this.recruit.tel,
        };
      }

      shortCaptcha(params)
        .then((res) => {
          console.log(res);
          this.$message.success("短信发送成功");
          this.count = 60;
          this.shortCode = false;
          this.timer = setInterval(() => {
            if (this.count > 0 && this.count <= 60) {
              this.count--;
            } else {
              this.shortCode = true;
              clearInterval(this.timer);
              this.timer = null;
            }
          }, 1000);
        })
        .catch((err) => {
          console.log(err);
          this.shortCode = true;
        });
    },
    // 图形验证码
    getcode() {
      captcha().then((res) => {
        this.codeImg = res.data;
      });
    },
    // 登录
    login() {
      let params = {};
      if (this.loginType == 1) {
        if (!this.job.account && !this.phone) {
          this.$message.warning("账号不能为空！");
          return;
        }
        if (!this.job.password && !this.phone) {
          this.$message.warning("密码不能为空！");
          return;
        }
        if (!this.job.captcha && !this.phone) {
          this.$message.warning("图形验证码不能为空！");
          return;
        }
        if (!this.job.tel && this.phone) {
          this.$message.warning("手机号不能为空！");
          return;
        }
        if (!this.job.captcha && this.phone) {
          this.$message.warning("图形验证码不能为空！");
          return;
        }
        if (!this.job.shortCaptcha && this.phone) {
          this.$message.warning("短信验证码不能为空！");
          return;
        }
        params = {
          ...this.job,
          loginType: this.loginType,
          loginStyle: this.phone ? 2 : 1,
          uuid: this.codeImg.imgId,
        };
      } else {
        if (!this.recruit.account) {
          this.$message.warning("账号不能为空！");
          return;
        }
        if (!this.recruit.password) {
          this.$message.warning("密码不能为空！");
          return;
        }
        if (!this.recruit.captcha) {
          this.$message.warning("验证码不能为空！");
          return;
        }
        params = {
          ...this.recruit,
          loginType: this.loginType,
          uuid: this.codeImg.imgId,
        };
      }
      login({ ...params, loginPlatform: 1 })
        .then((res) => {
          sessionStorage.setItem("userInfo", JSON.stringify(res.data));
          const subUrl = window.sessionStorage.getItem("subUrl");
          console.log(subUrl);
          if (subUrl) {
            this.$router.replace(subUrl);
            sessionStorage.removeItem("subUrl");
          } else {
            if (this.loginType == 1) {
              this.$router.replace("/");
            } else {
              this.$router.replace("/recruit/index");
            }
          }
          event.$emit("getInfoFn");
          this.$message.success("登录成功");
        })
        .catch(() => {
          this.getcode();
        });
    },
    tabType(str) {
      this.loginType = str;
    },
  },
};
</script>
<style scoped lang="scss">
.login {
  height: 100vh;
  background: transparent url("../assets/img/login/bg.png") no-repeat left top;
  background-size: 100% 100vh;
  .title {
    margin-bottom: 20px;
  }
  .content {
    width: 500px;
    transform: translate(-50%, -50%);
    position: absolute;
    left: 50%;
    top: 50%;
    text-align: left;
    .from {
      text-align: center;
      background: #fff;
      padding: 20px 60px 10px;
      border-radius: 4px 4px 4px 4px;
    }
    h3 {
      font-size: 26px;
      font-weight: bold;
      color: #333333;
      margin-bottom: 32px;
    }
    .tag {
      color: #999999;
      margin: -16px 0 32px;
      a {
        color: #0780e6;
      }
    }
    .bd {
      margin-top: 32px;
      &.el-zoom-in-center-leave-active {
        display: none;
      }
    }
    .tips {
      overflow: hidden;
      font-size: 14px;
      font-weight: 400;
      color: #666666;
      text-align: left;
      text-decoration: underline;
      margin-bottom: 20px;
      cursor: pointer;
      margin-top: -3px;
      p {
        float: right;
        text-decoration: underline;
        cursor: pointer;
      }
    }
    .item {
      margin-bottom: 16px;
      position: relative;
      img {
        width: 115px;
        height: 50px;
      }
      .icon-name {
        background: transparent url("../assets/img/login/icon-user.png")
          no-repeat 8px center;
        background-size: 21px 21px;
        position: absolute;
        left: 0;
        top: 0;
        width: 30px;
        height: 50px;
        z-index: 2;
      }
      .icon-password {
        background: transparent url("../assets/img/login/icon-password.png")
          no-repeat 8px center;
        background-size: 21px 21px;
        position: absolute;
        left: 0;
        top: 0;
        width: 30px;
        height: 50px;
        z-index: 2;
      }
      input {
        height: 50px;
        border-radius: 4px 4px 4px 4px;
        opacity: 1;
        border: 1px solid #c4c7d3;
        line-height: 50px;
        width: 100%;
        padding: 0 8px;
        &:-webkit-autofill {
          -webkit-box-shadow: 0 0 0px 1000px #fff inset !important; /*白色*/
        }
        &.name {
          text-indent: 30px;
        }
        &.phone {
          background: transparent url("../assets/img/login/icon-phone.png")
            no-repeat 8px center;
          background-size: 21px 21px;
          text-indent: 30px;
        }
        &.password {
          text-indent: 30px;
        }
        &.code {
          background: transparent url("../assets/img/login/icon-yzm.png")
            no-repeat 8px center;
          background-size: 21px 21px;
          text-indent: 30px;
        }
        &.license {
          background: transparent url("../assets/img/login/icon-name.png")
            no-repeat 8px center;
          background-size: 21px 21px;
          text-indent: 30px;
        }
      }
      .yzm {
        position: absolute;
        right: 0;
        font-size: 14px;
        color: #0780e6;
        top: 0;
        height: 50px;
        text-align: center;
        line-height: 50px;
        width: 110px;
        cursor: pointer;
        &::after {
          content: "";
          position: absolute;
          left: 0;
          height: 24px;
          width: 1px;
          background: #eee;
          top: 13px;
        }
        &.send {
          color: #999;
        }
      }
      &.code {
        input {
          width: 265px;
          border-radius: 4px 0 0 4px;
        }
        img {
          float: right;
        }
      }
    }
    .btn {
      img {
        width: 380px;
        height: 80px;
        cursor: pointer;
        &:hover {
          opacity: 0.8;
        }
      }
    }
  }
}
.tab-catalog-00 {
  font-size: 18px;
  font-weight: normal;
  border-bottom: 1px solid #cccccc;
  margin: 0 56px;
  overflow: hidden;
  a {
    color: #0780e6;
    padding-bottom: 10px;
    float: left;
    width: 50%;
    text-align: left;
  }
  a:last-child {
    float: right;
    text-align: right;
  }
  a.atthis {
    border-bottom: 3px solid #3580df;
    margin-bottom: -1px;
  }
}
</style>
